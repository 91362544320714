import React, { useState } from "react";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import { Drip7Modal } from "../../modals/drip7_modal";
import { PhishTemplateModal } from "../../modals/admin/phish_template_modal";
import { CurriculumModal } from "../../modals/admin/curriculum_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import Language from "../../helpers/language";
import { ContentType, CurriculumUsage } from "../../helpers/consts";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

export const PhishTemplatesTab = props => {
    const { question_categories, showToast, tenant_uid, tenants, onRefreshCategories } = props;

    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    const {is_msp} = usr_info?.tenant

    const [state, setState] = useState({
        search: "",
        selected: {},
        categories: [],
        category_uid: "",
        show_only_tenant: is_msp,
        confirm: null,
        show_curriculum: null,
        sort_by: "",
        sort_by_reverse: false
    });
    const { search, selected, categories, category_uid, confirm, show_curriculum, show_only_tenant, sort_by, sort_by_reverse } = state;

    const [show_phish, setShowPhish] = useState(null);

    const buildTemplates = (question_categories) => {
        let templates = [];

        //Load the categories
        question_categories.forEach( c => {
            if ( category_uid !== "" && c.uid !== category_uid ) {
                return;
            }
            c.questions.forEach( q => {
                if ( q.content_type === ContentType.Phish ) {
                    templates.push( {
                        category_uid: c.uid,
                        category_name: c.name,
                        tenant_name: c.tenant_name, ...q
                    } );
                }
            } )
        })

        //Sort by name
        templates.sort((a,b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )

        return templates
    }

    const filterTemplates = (template) => {
        const search_lc = search.toLowerCase();
        return search_lc === "" ||
               template.name.toLowerCase().indexOf(search_lc) >= 0 ||
               template.category_name.toLowerCase().indexOf(search_lc) >= 0 ||
               template.tenant_name.toLowerCase().indexOf(search_lc) >= 0
    }

    const filterPhishTemplates = (search, templates) => {
        //Do nothing
        if (search == "") {
            return templates;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < templates.length; i++) {
            const template = templates[i];
            if (template.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(template);
            }
        }

        return result;
    };

    const handleSortBy = (code) => {
        let sbr = sort_by == code ? !sort_by_reverse : false

        //Answers is special
        if ( code == "answers" ) {
            if ( sort_by == 'correct' ) {
                code = "incorrect"
            }
            else if ( sort_by == 'incorrect' ) {
                code = "unanswered"
            }
            else {
                code = "correct"
            }

            sbr = true
        }

        setState(prev => ({ ...prev,
            sort_by: code,
            sort_by_reverse: sbr
        }))
    }

    const handleSelect = (checked, uid) => {
        let { selected } = state;

        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        setState({ ...state, selected });
    };

    const handleSelectAll = (checked, templates) => {
        let selected = {};

        //Check all?
        if (checked) {
            for (let i = 0; i < templates.length; i++) {
                selected[templates[i].uid] = true;
            }
        }

        setState({ ...state, selected });
    };

    const handleChange = e => {
        setState(prev => ({ ...prev, [e.target.id]: e.target.value }))
    };

    const handleModalClose = template => {
        onRefreshCategories()
        setShowPhish(null);
    };

    const handleCurriculum = (show_curriculum) => {
        setState({ ...state, show_curriculum });
    }

    const handleExportCsv = () => {
        //Export a member list, and then redirect to it
        Util.fetch_js( "/question/export/", { question_uids: Object.keys(selected) },
            js => {
                window.location.href = js.url;
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const handleRemove = () => {
        Util.fetch_js( "/question/remove/", { question_uids: Object.keys(selected) },
            js => {
                showToast(Language.getSentenceCase('Deleted template(s)'), 'successful');
                onRefreshCategories()
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState({ ...state, confirm: null, selected: {} });
    };

    const handleCloseCurriculum = () => {
        setState({ ...state, show_curriculum: null });

    }

    const sortTemplates = (filtered_templates) => {
        //Sort?
        if (sort_by == "") {
            return filtered_templates
        }
        if (!sort_by_reverse) {

            if ( sort_by == 'correct' || sort_by == 'incorrect' || sort_by == 'unanswered') {
                filtered_templates.sort((a, b) => {
                    a.answers[sort_by] - b.answers[sort_by]
                })
            }
            else {
                filtered_templates.sort((a, b) =>
                    b[sort_by]
                        .toLowerCase()
                        .localeCompare(a[sort_by].toLowerCase())
                )
            }
        }
        else {
            if ( sort_by == 'correct' || sort_by == 'incorrect' || sort_by == 'unanswered') {
                filtered_templates.sort((a, b) => b.answers[sort_by] - a.answers[sort_by])
            }
            else {
                filtered_templates.sort((a, b) =>
                    a[sort_by]
                        .toLowerCase()
                        .localeCompare(b[sort_by].toLowerCase())
                )
            }
        }
        return filtered_templates
    }

    const confirmAction = action => {
        const new_confirm = {
            action,
            msg: Language.getSentenceCase('delete phish templates'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        };

        setState({ ...state, confirm: new_confirm });
    };

    let qc = ( show_only_tenant )? question_categories.filter( x => x.tenant_uid === tenant_uid ): question_categories
    const templates = buildTemplates( qc )
    //let filtered_templates = filterPhishTemplates(search, templates);
    let filtered_templates = templates.filter( t => filterTemplates(t))
    filtered_templates = sortTemplates( filtered_templates )

    const clean_categories = question_categories.filter( c => c.questions.filter(q => (!show_only_tenant || c.tenant_uid === tenant_uid) && q.content_type === ContentType.Phish).length > 0 )
    const cat_title = (clean_categories.find( c => c.uid === category_uid ) || {name: "Categories"})

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(selected).length > 0 && (
                            <button
                                type='button'
                                className='central__head-button central__head-button--remove'
                                name='remove-user'
                                onClick={() => confirmAction(handleRemove)}>
                                {Language.getTitleCase('Remove Template(s)')}
                            </button>
                        )}

                        {Object.keys(selected).length > 0 && (
                            <button
                                className='central__head-button central__head-button--clear'
                                onClick={handleExportCsv}>
                                {Language.getTitleCase( 'export as csv' )}
                            </button>
                        )}
                        <button
                            className='central__head-button central__head-button--solid central__head-button--add'
                            id='addContent'
                            type='button'
                            onClick={() => setShowPhish({name: ''})}>
                            {Language.getTitleCase('create template')}
                        </button>
                        <button
                                className='central__head-button central__head-button--solid central__head-button--add'
                                id='addContentPhish'
                                type='button'
                                onClick={() =>
                                    handleCurriculum({
                                        usage: CurriculumUsage.CAMPAIGN
                                    })
                                }>
                                {Language.getTitleCase('create phish campaign')}
                            </button>
                    </div>
                </div>

                <div className='central__filters ml-6 z-10'>
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='category_uid'
                            entries={clean_categories}
                            empty={{name: '---', uid: ''}}
                            value={cat_title}
                            onChange={handleChange}
                            display={x => x.name}
                            changeValue={x => x.uid}
                            overflow='max-h-60'
                            />
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <Drip7Dropdown
                            id='show_only_tenant'
                            entries={[false, true]}
                            value={show_only_tenant}
                            display={x => Language.getTitleCase(x? 'Show only My Tenant': 'show all tenants')}
                            onChange={handleChange}
                            />
                    </div>
                </div>

                <table className='management__table ml-6'>
                    <tbody>
                        <tr className='management__table-row management__table-row--head'>
                            <th className='management__table-head management__table-head--input'>
                                <div className='d7__checkbox-container'>
                                    <input
                                        className='d7__checkbox'
                                        aria-label="checkbox"
                                        type='checkbox'
                                        onChange={e => handleSelectAll(e.target.checked, filtered_templates) }
                                    />
                                    <span className='dash'></span>
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--name'>
                                <h5 className='management__table-heading'
                                    onClick={() => handleSortBy("name")}>
                                    {Language.getTitleCase('name')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <h5 className='management__table-heading'
                                onClick={() => handleSortBy("tenant_name")}>
                                    
                                    {Language.getTitleCase('tenant')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <h5 className='management__table-heading'
                                onClick={() => handleSortBy("category_name")}>
                                    {Language.getTitleCase('category name')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <h5 className='management__table-heading'
                                onClick={() => handleSortBy("answers")}>
                                    {Language.getTitleCase( 'answers' )}
                                </h5>
                            </th>
                        </tr>
                        {Object.entries(filtered_templates).map(
                            ([key, template]) => (
                                <tr
                                    className='management__table-row management__table-row--body'
                                    key={"template_row_" + key}>
                                    <td className='management__table-data management__table-data--input'>
                                        <div className='d7__checkbox-container'>
                                            <input
                                                className='d7__checkbox'
                                                type='checkbox'
                                                aria-label="checkbox"
                                                checked={
                                                    template.uid in selected
                                                }
                                                onChange={e =>
                                                    handleSelect(
                                                        e.target.checked,
                                                        template.uid
                                                    )
                                                }
                                            />
                                            <span className='checkmark'></span>
                                        </div>
                                    </td>
                                    <td className='management__table-data management__table-data--name'
                                        onClick={() => setShowPhish(template)}>
                                        <h5 className='text-sm font-semibold ml-8 cursor-pointer'>
                                            {template.name}
                                        </h5>
                                    </td>
                                    <td className='management__table-data management__table-data--name'
                                        onClick={() => setShowPhish(template)}>
                                        <h5 className='text-sm font-semibold cursor-pointer'>
                                            {template.tenant_name}
                                        </h5>
                                    </td>
                                    <td
                                        className='management__table-data management__table-data--name'
                                        onClick={() => setShowPhish(template)}>
                                        <h5 className="text-sm font-semibold cursor-pointer">
                                            {template.category_name}
                                        </h5>
                                    </td>
                                    <td className='questions__table-data'>
                                        <h5 className='questions__table-text cursor-pointer'
                                            onClick={() => onQuestionClick( template, "answers" )}>
                                            <span style={{ color: "green" }}>{template.answers.correct}</span>
                                            {" / "}
                                            <span style={{ color: "red" }}>{template.answers.incorrect}</span>
                                            {" / "}
                                            <span>{template.answers.unanswered}</span>
                                        </h5>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState({ ...state, confirm: null })}
                    />
                )}
            </div>

            <Drip7Modal>
                {show_phish != null && (
                    <PhishTemplateModal
                        question={show_phish}
                        tenant_uid={tenant_uid}
                        categories={categories}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
                {show_curriculum != null &&
                    <CurriculumModal
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        curriculum={show_curriculum}
                        showToast={showToast}
                        onClose={handleCloseCurriculum}
                    />
                }
            </Drip7Modal>
        </div>
    );
};
