import React, { useEffect, useState } from "react";

import DatePicker from "react-multi-date-picker";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import {
    Access,
    CurriculumUsage,
    OffsetMode,
    QueueDelivery,
    QueueDeliveryUi,
    QueuePhishDelivery
} from "../../helpers/consts";

import { queueAudienceRaw } from "../../helpers/content";
import {
    QueueScheduleWidget
} from "../../components/admin/queue_schedule_widget";
import {
    QueueCampaignWidget
} from "../../components/admin/queue_campaign_widget";
import { SelectItems } from "../../components/select_items";
import { InputWithArrows } from "../../components/input_with_arrows";
import {
    QueueSchedulePhishWidget
} from "../../components/admin/queue_schedule_phish_widget";
import { ConfirmModal } from "../confirm_modal";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

export const QueueModal = (props) => {
    const { tenant_uid, showToast, onClose, phishQueue, policyQueue } = props
    let raw_queue = props.queue;
    if (raw_queue == true || raw_queue == null || raw_queue == undefined) {
        raw_queue = {
            name: "",
            user_count: 0,
            delivery: policyQueue? QueueDelivery.Splash: QueueDelivery.Drip,
            active: true,
            offset: 0,
            offset_mode: OffsetMode.DAYS,
            leaderboard: false,
            curriculum: { uid: "" },
            user_start_day: true,
            users: [],
            groups: []
        };
    }

    const safeQueue = (queue, users) => {
        queue["curriculum_uid"] = queue.curriculum.uid;

        let user_uids = ('users' in queue)? queue.users.map( x => x.uid ): []
        let groups = ('groups' in queue)? queue.groups.map( x => x.uid ): []

        //If no user is selected, we can attempt to select all users
        if ( user_uids.length == 0 ) {
            user_uids = users.map( x => x.uid )
        }

        return [queue, user_uids, groups];
    }

    const default_curriculum_usage = () => {
        if ( policyQueue ) {
            return CurriculumUsage.POLICY
        }
        return (raw_queue.delivery !== QueueDelivery.Phish) ? CurriculumUsage.CURRICULUM : CurriculumUsage.CAMPAIGN
    }

    const default_curriculum = {
        name: "---",
        usage: default_curriculum_usage(),
        next_curriculum_uid: '',
        question_count: 0,
        collection_question_count: 0
    };

    const [safe_queue, _0, _1] = safeQueue(raw_queue, []);

    const [state, setState] = useState({
        queue: safe_queue,
        queue_curriculum: default_curriculum,
        queue_track: { uid: null, name: "---" },
        queue_tracks: [],
        selected_users: [],
        selected_groups: [],
        curriculums: [],
        users: [],
        groups: [],
        campaigns: [],
        schedules: [],
        show_audience: false,
        show_phish_schedule: false,
        confirm: null,
    })
    const { queue, queue_curriculum, queue_track, queue_tracks, selected_users, selected_groups,
            curriculums, users, groups, campaigns, schedules,
            show_audience, show_phish_schedule, confirm } = state

    useEffect(() => {
        //Download queue detail
        if ("uid" in queue && queue.uid != "") {
            Util.fetch_js( "/queue/detail/", { queue_uid: queue.uid },
                js => {
                    const [queue, selected_users, selected_groups] = safeQueue(js, users );
                    const queue_track = (queue.queue_track != null)? queue.queue_track: {name: '---', uid: null}

                    setState(prev => ({ ...prev,
                        queue,
                        queue_curriculum: queue.curriculum,
                        queue_track,
                        selected_users,
                        selected_groups,
                        campaigns: queue.campaigns,
                        schedules: queue.schedules,
                    }))
                    
                    //Need to call human/list after we get selected users from the queue, and pass that list to the human list
                    // (rather than using selected_users from state, since that's stale data now)
                    fetchHumanList(selected_users);
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
        //Fill the schedule we empty defaults
        else {
            fetchHumanList(null);

            let schedules = []
            const max_count = (queue.delivery == QueueDelivery.Phish)? 1: 5
            for ( let i = 0; i < 7; i++ ) {
                schedules.push({
                    day_of_week: i,
                    count: (i < max_count)? 1: 0,
                })
            }

            const campaigns = [{ month: 0, week_of_month: 0, day_of_week: 0 }]

            setState(prev => ({ ...prev,
                schedules,
                campaigns,
            }))
        }

        Util.fetch_js( "/group/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    groups: js.groups,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        Util.fetch_js( "/curriculum/list/", { tenant_uid, access: Access.EXEC, policy_only: policyQueue },
            js => {
                setState(prev => ({ ...prev,
                    curriculums: phishQueue? js.curriculums.filter(cur => cur['usage'] == 'Campaign'):js.curriculums.filter(cur => cur['usage'] != 'Campaign')
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        Util.fetch_js( "/queue_track/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    queue_tracks: js.queue_tracks,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        )
    }, [])

    const fetchHumanList = (selected_users) => {
        //console.log('before human list. selected_users length is ' + selected_users.length.toString())
        //Get users and groups
        Util.fetch_js( "/human/list/", { tenant_uid },
            js => {
                if ( state.users.length === 0 && queue.everyone ) {
                    selected_users = null
                }

                // Need to re-fetch selected_users from state in case /queue/detail updated it
                //console.log('after human list. selected_users length is ' + selected_users.length.toString())
                const user_uids = (selected_users)? selected_users: js.users.map( x => x.uid )

                setState(prev => ({ ...prev,
                    users: js.users,
                    selected_users: user_uids,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const calculateSchedule = (campaigns) => {
        let schedules = []
        for ( let i = 0; i < 7; i++ ) {
            schedules.push({ day_of_week: i, count: 0 })
        }

        campaigns.forEach( x => {
            schedules[x.day_of_week].count = 1
        })

        return schedules
    }

    const handleChecked = (e) => {
        let { queue } = state;
        queue[e.target.name] = e.target.checked;

        setState(prev => ({...prev, queue }))
    }

    const handleChange = (e) => {
        /*
        if (name == "queue_status") {
            queue.active = value != "Inactive";

            //If we're transitioning into date bound, Sweet, make sure the dates are reasonable
            if (value == "Date bound") {
                if (queue.start_day <= 0) {
                    queue.start_day = Util.dateToDay( new Date().getTime(), true )
                }
                if (queue.end_day <= 0) {
                    queue.end_day =
                        Util.dateToDay(new Date().getTime(), true) + 1;
                }

                //Force the range value to whatever we currently have
                range_picker[0] = Util.dayToDate(queue.start_day);
                range_picker[1] = Util.dayToDate(queue.end_day);
            }
        }
        */

        const { name, value } = e.target

        if ( name == 'queue_track' ) {
            const qts = queue_tracks.filter( qt => qt.uid == value )
            const queue_track = (qts.length > 0)? qts[0]: { name: '---', uid: null }
            setState(prev => ({ ...prev, queue_track }))
        }
        else {
            setState(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleQueueChange = (e) => {
        const { name, value } = e.target

        let { queue, curriculums } = state;
        queue[name] = value;

        if ( name == "delivery" ) {
            queue.leaderboard = (value == "Splash")
        }

        //Reset the offset when the mode changes
        if ( name == "offset_mode" ) {
            queue.offset = 0
        }

        //Special case, default the queue name to the curriculum
        if ( name == "curriculum_uid" ) {
            curriculums.forEach( cur => {
                if (cur.uid != value) {
                    return
                }

                //Update the queue
                queue.name = cur.name;
                if (cur.usage.toLowerCase() == "campaign") {
                    queue.delivery = QueueDelivery.Phish;
                }
                else if ( cur.usage.toLowerCase() == "curriculum" && queue.delivery == QueueDelivery.Phish ) {
                    queue.delivery = QueueDelivery.Drip;
                }
            })
        }

        setState(prev => ({ ...prev,
            queue
        }))
    }

    const handleDeleteQuestions = () => {
        Util.fetch_js( "/queue/delete_questions/", { queue_uid: queue.uid },
            js => {
                showToast(Language.getSentenceCase('deleted questions') +` ${js.count}`, 'successful');
                onClose(true)
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleSave = () => {
        let { queue, selected_users, selected_groups, campaigns, schedules } = state

        const user_uids = selected_users // (selected_users.length != users.length)? selected_users: []

        let payload = {
            ...queue,
            queue_uid: queue.uid,
            user_uids,
            group_uids: selected_groups,
            queue_track_uid: (queue_track.uid != null)? queue_track.uid: "",
            everyone: selected_users.length === users.length,
            campaigns,
            schedules,
        };

        // Create?
        if (!("uid" in queue) || queue.uid == "") {
            payload['tenant_uid'] = tenant_uid
            Util.fetch_js( "/queue/create/", payload,
                js => {
                    showToast(Language.getSentenceCase('created queue'), 'successful');
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
        else {
            Util.fetch_js( "/queue/modify/", payload,
                js => {
                    showToast(Language.getSentenceCase('updated queue'), 'successful');

                    //Attempt delete?
                    if ( !queue.active ) {
                        confirmAction( handleDeleteQuestions )
                    }
                    else {
                        onClose(true);
                    }
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    }

    const handleDateChange = (e) => {
        const queue = { ...state.queue,
            offset: Util.getDoy( new Date(e.unix * 1000)) - 1
        }
        /*
        const [start, end] = e;
        if (start == undefined || end == undefined) {
            return;
        }

        queue.start_day = Util.dateToDay(start.unix * 1000);
        queue.end_day = Util.dateToDay(end.unix * 1000);
         */

        setState(prev => ({...prev, queue }))
    }

    const handleDateNow = () => {
        const queue = { ...state.queue,
            offset: Util.getDoy( new Date() ) - 1
        }

        /*
        const range_picker = [
            Util.dayToDate(queue.start_day),
            Util.dayToDate(queue.start_day)
        ];
         */

        setState(prev => ({...prev,
            queue
        }))
    }

    const handleScheduleChange = ( delivery, doy, count ) => {
        let { schedules } = state

        //Define the delivery based on a common timeline
        if ( delivery == QueueDeliveryUi.WEEKDAY || delivery == QueueDeliveryUi.EVERY_DAY ) {
            const day_count = (delivery == QueueDeliveryUi.EVERY_DAY)? 7: 5
            for ( let i = 0; i < 7; i++ ) {
                schedules[i] = {...schedules[i],
                    day_of_week: i,
                    count: (i < day_count)? count : 0
                }
            }
        }
        //Fully customize the delivery, but only based on one day of week
        else if ( delivery == QueueDeliveryUi.CUSTOM  && doy >= 0 && doy < 7) {
            schedules[doy] = {...schedules[doy], count}
        }

        setState(prev => ({ ...prev,
            schedules
        }))
    }

    const handleSchedulePhishChange = ( delivery, doy, count ) => {
        let { schedules } = state

        //Define the delivery based on a common timeline
        if ( delivery == QueuePhishDelivery.DELIVERY_SPAN ) {
            //Distribute my numbers
            const weeks = Math.floor(count / 7)
            for ( let i = 0; i < 7; i++ ) {
                schedules[i] = { ...schedules[i],
                    day_of_week: i,
                    count: weeks + (((count % 7) > i)? 1: 0),
                }
            }
        }
        //Fully customize the delivery, but only based on one day of week
        else if ( delivery == QueuePhishDelivery.CUSTOM && doy >= 0 && doy < 7) {
            schedules[doy] = {...schedules[doy], count}
        }

        setState(prev => ({ ...prev,
            schedules
        }))
    }

    const handleAddCampaign = () => {
        let { campaigns } = state

        campaigns.push({ month: 0, week_of_month: 0, day_of_week: 0 })
        const schedules = calculateSchedule( campaigns )

        setState(prev => ({ ...prev,
            campaigns,
            schedules,
        }))
    }

    const handleCampaignChange = ( idx, key, value ) => {
        let { campaigns, schedules } = state

        if ( idx < 0 || idx >= campaigns.length ) {
            return
        }

        //Assign the value
        campaigns[idx][key] = value

        //Day of week change? update it
        if ( key == 'day_of_week') {
            schedules = calculateSchedule( campaigns )
        }

        //Update teh delivery
        setState( prev => ({ ...prev,
            schedules,
            campaigns
        }))
    }

    const handleRemoveCampaign = ( idx ) => {
        let { campaigns } = state

        if ( idx >= 0 && idx < campaigns.length ) {
            campaigns.splice( idx, 1 )
        }

        setState( prev => ({ ...prev,
            campaigns
        }))
    }

    const confirmAction = (action) => {
        const new_confirm = {
            action,
            msg: Language.getSentenceCase('delete unanswered questions'),
            yes: Language.getTitleCase('yes'),
            no: Language.getTitleCase('__no__')
        }

        setState(prev => ({ ...prev,
            confirm: new_confirm
        }))
    }

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    const filtered_curriculums = curriculums.filter( c => c.delivery_count > 0 )

    let curriculum = queue_curriculum
    filtered_curriculums.forEach( c => {
        if (c.uid == queue.curriculum_uid) {
            curriculum = c
        }
    })

    const delivery_options = {
        Drip: "Drip",
        Splash: "Splash",
    }

    const show_schedule = ('uid' in curriculum) && curriculum.usage == CurriculumUsage.CURRICULUM && schedules.length > 0 && queue.delivery == QueueDelivery.Drip
    const show_campaign = ('uid' in curriculum) && curriculum.usage == CurriculumUsage.CAMPAIGN
    const is_phish_schedule = show_campaign && schedules.length > 0

    let months = []
    for ( let i = 0; i < 12; i++ ) {
        months.push( Util.toLongMonth(i) )
    }

    console.log( queue )
    console.log( curriculum )

    let current_date = new Date(new Date().getFullYear(), 0, 1)
    current_date.setDate(queue.offset + 1)
    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('queue')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="queue-grid">
                <div
                    className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase(curriculum.usage)}
                        {curriculum.usage !== CurriculumUsage.POLICY ? `: ${curriculum.delivery_count} ${Language.getString('question(s)')}` : ""}
                    </label>
                    <div
                        className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id="curriculum_uid"
                            name="curriculum_uid"
                            entries={filtered_curriculums}
                            value={curriculum}
                            onChange={handleQueueChange}
                            display={(x) => x.name}
                            changeValue={(x) => x.uid}
                            overflow="max-h-60"
                            />
                    </div>
                </div>

                <div
                    className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <input
                        type='text'
                        className='mt-1 border-1 border-gray-300 rounded'
                        id='newQuestionCategory'
                        placeholder='Name'
                        name='name'
                        value={queue.name}
                        onChange={handleQueueChange}
                    />
                </div>

                {curriculum.usage == "Curriculum" && <>
                    <div
                        className='basic-modal__field basic-modal__field--add-question grid-item'>
                        <label className='basic-modal__label'
                               htmlFor='newQuestionCategory'>
                            {Language.getTitleCase("Delivery")}
                        </label>
                        <div
                            className='dropdown dropdown--attached dropdown--add-question'>
                            <Drip7Dropdown
                                id="delivery"
                                name="delivery"
                                entries={Object.values(delivery_options)}
                                value={queue.delivery}
                                onChange={handleQueueChange}
                                display={(x) => Language.getTitleCase(x)}
                                changeValue={(x) => x}
                                />
                        </div>
                    </div>

                    <div
                        className='basic-modal__field basic-modal__field--add-question grid-item'>
                        <label className='basic-modal__label'>
                            {Language.getTitleCase("Schedule mode")}
                        </label>
                        <div className='dropdown dropdown--attached dropdown--add-question dropdown-side-by-side'>
                            <Drip7Dropdown
                                id="offset_mode"
                                name="offset_mode"
                                entries={Object.values(OffsetMode)}
                                value={queue.offset_mode}
                                onChange={handleQueueChange}
                                display={(x) => Language.getTitleCase(x)}
                                />

                            {queue.offset_mode == OffsetMode.DAYS &&
                                <InputWithArrows
                                    name="offset"
                                    value={queue.offset}
                                    onChange={handleQueueChange}
                                />
                            }

                            {queue.offset_mode == OffsetMode.MONTHS &&
                                <Drip7Dropdown
                                    id="offset"
                                    name="offset"
                                    entries={[0,1,2,3,4,5,6,7,8,9,10,11]}
                                    value={queue.offset}
                                    onChange={handleQueueChange}
                                    display={x => Util.toLongMonth(x)}
                                    align="right"
                                    overflow="max-h-60"
                                    />
                            }

                            {queue.offset_mode == OffsetMode.DATE &&
                                <div
                                    className='basic-modal__field basic-modal__field--add-question grid-item'>
                                    <label
                                        className='basic-modal__label'
                                        htmlFor='newQuestionCategory'>
                                        {Language.getSentenceCase('dates valid through')} (
                                        <a href='#' onClick={handleDateNow}>
                                            {Language.getTitleCase('now')}
                                        </a>
                                        )
                                    </label>
                                    <div
                                        className='dropdown dropdown--attached dropdown--add-question'>
                                        <h5 className='management__table-text'>
                                            <DatePicker
                                                value={current_date}
                                                onChange={handleDateChange}
                                            />
                                        </h5>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </>}
            </div>

            {/* && curriculum.usage == "Curriculum" &&
                <div className="queue-grid">
                    <div
                        className='basic-modal__field basic-modal__field--add-question grid-item'>
                        <label className='basic-modal__label'
                               htmlFor='newQuestionCategory'>
                            {Language.getTitleCase("Queue Track")}
                        </label>
                        <div
                            className='dropdown dropdown--attached dropdown--add-question'>
                            <DropdownButton title={queue_track.name}>
                                <Dropdown.Item
                                    as='button'
                                    onClick={handleChange}
                                    name='queue_track'
                                    value={null}
                                    key={"queue_track_-1"}>
                                    ---
                                </Dropdown.Item>
                                {Object.entries(queue_tracks).map(([key, qt]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        name='queue_track'
                                        value={qt.uid}
                                        key={"queue_track_" + key}>
                                        {qt.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                    </div>
                </div>
            */}

            <div className="queue-grid">
                <div
                    className='basic-modal__field basic-modal__field--add-question grid-item-row'
                    style={{maxHeight: 'unset'}}>
                    <label className='basic-modal__label'>
                        {Language.getTitleCase("settings")}
                    </label>

                    <div
                        className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            name='active'
                            aria-label="checkbox"
                            readOnly
                            checked={queue.active}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({
                                   target: {
                                       name: 'active',
                                       checked: !queue.active
                                   }
                               })}>
                            {Language.getSentenceCase("Active")}
                        </label>
                    </div>

                    {!policyQueue &&
                        <div
                            className='customization-checkbox grid-item queue-grid--settings'>
                            <input
                                type='checkbox'
                                name='leaderboard'
                                aria-label="checkbox"
                                readOnly
                                checked={queue.leaderboard}
                                onClick={handleChecked}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='users-can-edit'
                                   onClick={x => handleChecked({
                                       target: {
                                           name: 'leaderboard',
                                           checked: !queue.leaderboard
                                       }
                                   })}>
                                {Language.getSentenceCase("Leaderboard")}
                            </label>
                        </div>
                    }

                    {!policyQueue &&
                        <div
                            className='customization-checkbox grid-item queue-grid--settings'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='refresh_yearly'
                                readOnly
                                checked={queue.refresh_yearly}
                                onClick={handleChecked}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='users-can-edit'
                                   onClick={x => handleChecked({
                                       target: {
                                           name: 'refresh_yearly',
                                           checked: !queue.refresh_yearly
                                       }
                                   })}>
                                {Language.getSentenceCase('refresh annually')}
                            </label>
                        </div>
                    }
                </div>
            </div>

            {show_schedule &&
                <QueueScheduleWidget
                    schedules={schedules}
                    question_count={curriculum.delivery_count}
                    limit={curriculum.limit}
                    onScheduleChange={handleScheduleChange}
                />
            }

            <div className='queue-grid'>
                <div className='basic-modal__field basic-modal__field--add-question grid-item--large cursor-pointer'
                    onClick={() => handleChange({
                        target: {
                            name: 'show_audience',
                            value: !show_audience
                        }
                    })}>

                    <label className='basic-modal__label'>
                        {Language.getSentenceCase('current audience')}: {queueAudienceRaw(selected_users.length, users.length, groups, selected_groups, false)}
                        &nbsp;
                        <i className='drop-chevron'
                           style={showStyle(show_audience)}></i>
                    </label>
                </div>
            </div>

            {show_audience && <>
                <SelectItems
                    selected={selected_users}
                    items={users}
                    title={"users"}
                    searchTitle={"search users by name"}
                    onChange={value => handleChange({
                        target: {
                            name: 'selected_users',
                            value
                        }
                    })}
                />

                <SelectItems
                    selected={selected_groups}
                    items={groups}
                    title={"groups"}
                    hideSearch={true}
                    hideAll={true}
                    onChange={value => handleChange({
                        target: {
                            name: 'selected_groups',
                            value
                        }
                    })}
                />
            </>}

            {show_campaign && <>
                <QueueCampaignWidget
                    campaigns={campaigns}
                    onChange={handleCampaignChange}
                    onRemove={handleRemoveCampaign}
                />

                <div className='queue-grid'>
                    <button
                        className='basic-modal__button basic-modal__button--solid basic-modal__button--add'
                        onClick={handleAddCampaign}>
                        <svg width="14" height="14" viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.0625 5.96875H8.84375V1.75C8.84375 1.25195 8.4043 0.8125 7.90625 0.8125H6.96875C6.44141 0.8125 6.03125 1.25195 6.03125 1.75V5.96875H1.8125C1.28516 5.96875 0.875 6.4082 0.875 6.90625V7.84375C0.875 8.37109 1.28516 8.78125 1.8125 8.78125H6.03125V13C6.03125 13.5273 6.44141 13.9375 6.96875 13.9375H7.90625C8.4043 13.9375 8.84375 13.5273 8.84375 13V8.78125H13.0625C13.5605 8.78125 14 8.37109 14 7.84375V6.90625C14 6.4082 13.5605 5.96875 13.0625 5.96875Z"
                                fill="currentColor"/>
                        </svg>
                        {Language.getTitleCase('add delivery')}
                    </button>
                </div>
            </>}


            {is_phish_schedule && !show_phish_schedule &&
                <div className='queue-grid'>
                    <div
                        className='basic-modal__field basic-modal__field--add-question grid-item--large cursor-pointer'
                        onClick={() => handleChange({
                            target: {
                                name: 'show_phish_schedule',
                                value: true
                            }
                        })}>

                        <label className='basic-modal__label'>
                            {Language.getSentenceCase('advanced delivery distribution')}
                            &nbsp;
                            <i className='drop-chevron'
                               style={showStyle(show_phish_schedule)}></i>
                        </label>
                    </div>
                </div>
            }

            {show_phish_schedule &&
                <QueueSchedulePhishWidget
                    schedules={schedules}
                    onScheduleChange={handleSchedulePhishChange}
                />
            }


            <div className='basic-modal__button-container'>
                {!queue.active &&
                    <button
                        className='basic-modal__button--solid basic-modal__button--delete basic-button__button--remove_left'
                        onClick={handleDeleteQuestions}>
                        {Language.getTitleCase('Delete Unanswered')}
                    </button>
                }

                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(true)}
                    onNo={() => onClose()}
                />
            )}
        </div>
    );
}
