import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { ReportGenerateModal } from "../../modals/admin/report_generate_modal";
import { letterGrade } from "../../helpers/content";
import { Drip7Button } from "../../components/drip7_button";
import { Drip7Combobox } from "../../components/drip7_combobox";

export const ReportProgressTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        search: "",
        sort_by: '',
        reverse: false,
        selected: {},
        delivered: 0,

        users: [],
        queues: [],
        queue_uid: null,
    })
    const { search, sort_by, reverse, selected, delivered, users, queues, queue_uid } = state

    useEffect(() => {
        Util.fetch_js( "/queue/list/", { tenant_uid },
            js => {
                //Sort Queues by active and then by name
                const queues = js.queues.sort((a, b) => {
                    if (a.active == b.active) {
                        return a.name.localeCompare(b.name)
                    }
                    else {
                        return (a.active)? -1 : 1
                    }
                })

                const queue_uid = (queues.length > 0)? queues[0].uid : null
                updateUsers( queue_uid )

                setState(prev => ({ ...prev,
                    queues,
                    queue_uid,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }, [props])

    const updateUsers = ( queue_uid ) => {
        if ( queue_uid == null ) {
            return
        }

        Util.fetch_js( "/report/user_queue_progress/", { tenant_uid, queue_uid },
            js => {
                setState(prev => ({ ...prev,
                    users: js.users,
                    delivered: js.progress,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const handleSelect = (checked, uid) => {
        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleSelectAll = (checked) => {
        let selected = {}

        //Check all?
        if (checked) {
            /*
            for (let i = 0; i < reports.length; i++) {
                selected[reports[i].uid] = true;
            }
             */
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleContext = (entry) => {
        if ( entry === null || entry === undefined ) {
            return
        }

        updateUsers( entry.uid )
        setState(prev => ({...prev, queue_uid: entry.uid }))
    }

    const handleChanged = (e) => {
        setState(prev => ({...prev, [e.target.id]: e.target.value }))
    }

    const handleSortBy = (sort_by) => {
        const reverse = (state.sort_by == sort_by)
        if ( state.sort_by == sort_by && state.reverse ) {
            setState(prev => ({...prev,
                sort_by,
                reverse: false,
            }))
        }
        else {
            setState(prev => ({...prev,
                sort_by,
                reverse,
            }))
        }
    }

    const handleExportCsv = () => {
        //Export a member list, and then redirect to it
        Util.postDataAndOpenNewTab( "/report/user_queue_progress_report/", { queue_uid } )
    }


    const filterUsers = (users, search, sort_by, reverse) => {
        let filtered_users = users.filter(u => u.name.toLowerCase().includes(search.toLowerCase()) || u.email.toLowerCase().includes(search.toLowerCase()))
        if ( sort_by == 'name' ) {
            filtered_users = filtered_users.sort((a, b) => {
                return (!reverse)? a[sort_by].localeCompare(b[sort_by]): b[sort_by].localeCompare(a[sort_by])
            })
        }
        else if ( sort_by == 'start_day' ) {
            let convDate = d => {
                const ps = d.split('-');
                return Date.parse(ps[2] + '-' + (ps[0].length == 1 ? '0' + ps[0] : ps[0]) + '-' + (ps[1].length == 1 ? '0' + ps[1] : ps[1]))
            }
            filtered_users = filtered_users.sort((a, b) => {
                let conva = convDate(a[sort_by]);
                let convb = convDate(b[sort_by]);
                return (!reverse)? conva - convb: convb - conva;
            })
        }
        else if ( sort_by == 'progress' || sort_by == 'completion' ) {
            filtered_users = filtered_users.sort((a, b) => {
                if ( a[sort_by] == b[sort_by] ) {
                    return (!reverse)? (a.grade - b.grade): (b.grade - a.grade)
                }

                return (!reverse)? (a[sort_by] - b[sort_by]): (b[sort_by] - a[sort_by])
            })
        }
        else if ( sort_by != '' ) {
            filtered_users = filtered_users.sort((a, b) => {
                return (!reverse)? (a[sort_by] - b[sort_by]): (b[sort_by] - a[sort_by])
            })
        }

        return filtered_users
    }

    const filtered_users = filterUsers( users, search, sort_by, reverse )
    const queue = (queue_uid != null)? queues.find(q => q.uid == queue_uid) : { name: "Queue", curriculum: { delivery_count: 0 } }

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChanged}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        <Drip7Button
                            onClick={handleExportCsv}>
                            {Language.getTitleCase('Export')}
                        </Drip7Button>
                            <Drip7Combobox
                                entries={queues.filter(x => x.active).map(t => ({name: t.name, uid: t.uid}))}
                                selected_idx={queues.filter(x => x.active).findIndex(t => t.uid === queue_uid)}
                                onEntry={handleContext}/>
                            {/*}
                        <div className='dropdown dropdown--attached dropdown--question-filter'>
                            <DropdownButton  title={queue.name}>
                                {Object.entries(queues.filter(x => x.active )).map( ([key, queue]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChanged}
                                        id='queue_uid'
                                        value={queue.uid}
                                        key={"queue_uid_" + key}>
                                        {queue.name}
                                    </Dropdown.Item>
                                    )
                                )}
                                {queues.filter(x => !x.active ).length > 0 && <Dropdown.Divider />}
                                {Object.entries(queues.filter(x => !x.active )).map( ([key, queue]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChanged}
                                        id='queue_uid'
                                        value={queue.uid}
                                        key={"queue_uid_" + key}>
                                        {queue.name}
                                    </Dropdown.Item>
                                    )
                                )}
                            </DropdownButton>
                        </div>
                            {*/}
                    </div>
                </div>

                <div className='central__head-button-container management__table px-4'>
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newQuestionCategory'>
                            {queue.name}
                        </label>

                        <div className='customization-checkbox grid-item queue-grid--settings'>
                            <ul>
                                <li className='basic-modal__info'>Delivered: {delivered}%</li>
                                <li className='basic-modal__info'>Question Count: {queue.curriculum.delivery_count}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <table className='management__table mx-4'>
                    <tbody>
                        <tr className='management__table-row management__table-row--head'>
                            <th className='management__table-head management__table-head--input'>
                                <div className='d7__checkbox-container'>
                                    <input
                                        className='d7__checkbox'
                                        aria-label="checkbox"
                                        type='checkbox'
                                        checked={false}
                                        onChange={e => handleSelectAll( e.target.checked )}/>
                                    <span className='dash'></span>
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--name'
                                onClick={() => handleSortBy('name')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('name')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'
                                onClick={() => handleSortBy('completion')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('delivery')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'
                                onClick={() => handleSortBy('progress')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('completion')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'
                                onClick={() => handleSortBy('grade')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('grade')}
                                </h5>
                            </th>
                            {false &&
                            <th className='management__table-head management__table-head--questions'
                                onClick={() => handleSortBy('grade')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('percentage')}
                                </h5>
                            </th>
                            }
                            <th className='management__table-head management__table-head--questions'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('answers')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'
                                onClick={() => handleSortBy('start_day')}>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('curriculum start')}
                                </h5>
                            </th>
                        </tr>
                        {Object.entries(filtered_users).map( ([key, user]) => (
                            <tr
                                className='management__table-row management__table-row--body'
                                key={"report_row_" + key}>
                                <td className='management__table-data management__table-data--input'>
                                    <div className='d7__checkbox-container'>
                                        <input
                                            className='d7__checkbox'
                                            type='checkbox'
                                            aria-label='checkbox'
                                            checked={ user.uid in selected }
                                            onChange={e => handleSelect( e.target.checked, user.uid ) } />
                                        <span className='checkmark'></span>
                                    </div>
                                </td>
                                <td className='management__table-data management__table-data--name'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {user.name}
                                    </h5>
                                </td>
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {user.completion}%
                                    </h5>
                                </td>
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {user.progress}%
                                    </h5>
                                </td>
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {(user.progress > 0)? letterGrade(user.grade): '-'}
                                    </h5>
                                </td>
                                {false &&
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {user.grade}%
                                    </h5>
                                </td>
                                }
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        <span style={{color: "green"}}>
                                            {user.correct}
                                        </span>
                                        {" "} / {" "}
                                        <span style={{color: "red"}}>
                                            {user.incorrect}
                                        </span>
                                        {" "} / {" "}
                                        <span>
                                            {user.unanswered}
                                        </span>
                                    </h5>
                                </td>
                                <td className='management__table-data'
                                    onClick={() => setState(prev => ({...prev })) }>
                                    <h5 className='management__table-text'>
                                        {(user.start_day.length>0)? user.start_day: ''}
                                    </h5>
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div>

            <Drip7Modal>
                {false && (
                  <ReportGenerateModal
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                    generate_only={generate_only}
                    onClose={handleModalClose}
                  />
                )}
            </Drip7Modal>
        </div>
    );
};
