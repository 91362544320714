import React, {useEffect, useState} from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { ReportGenerateModal } from "../../modals/admin/report_generate_modal";
import { Drip7Button } from "../../components/drip7_button";

export const ReportGenerateTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        search: "",
        reports: [],
        selected: {},
        confirm: null,
        show_generate: null,
        generate_only: false,
    })
    const { search, reports, selected, confirm, show_generate, generate_only } = state

    const updateList = () => {
        Util.fetch_js( "/report/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    reports: js.reports
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const filterReports = (search, reports) => {
        //Do nothing
        if (search == "") {
            return reports
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < reports.length; i++) {
            const report = reports[i]
            if (report.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(report)
            }
        }

        return result
    }

    const handleSelect = (checked, uid) => {
        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleSelectAll = (checked) => {
        let selected = {}

        //Check all?
        if (checked) {
            for (let i = 0; i < reports.length; i++) {
                selected[reports[i].uid] = true;
            }
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.id]: e.target.value }))
    }

    const handleModalClose = (report) => {
        updateList()
        setState( prev => ({...prev,
            show_generate: null,
            generate_only: false
        }))
    }

    const handleRemove = () => {
        Util.fetch_js( "/report/remove/", { report_uids: Object.keys(selected) },
            js => {
                showToast(Language.getTitleCase('deleted reports'), 'successful');
                updateList()
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev, confirm: null, selected: {} }))
    }

    const confirmAction = (action) => {
        const new_confirm = {
            action,
            msg: Language.getTitleCase('remove reports'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        };

        setState(prev => ({...prev, confirm: new_confirm }))
    }

    const filtered_reports = filterReports(search, reports)

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(selected).length > 0 && (
                            <Drip7Button
                                nameBtn='remove-user'
                                color='red-500'
                                onClick={() => confirmAction(handleRemove) }>
                                {Language.getTitleCase('remove reports')}
                            </Drip7Button>
                        )}
                        <Drip7Button
                            idBtn='generateContent'
                            onClick={() => setState(prev => ({...prev, show_generate: true, generate_only: true })) }>
                            {Language.getTitleCase('generate report')}
                        </Drip7Button>
                        <Drip7Button
                            idBtn='addContent'
                            onClick={() => setState(prev => ({...prev, show_generate: true })) }>
                            {Language.getTitleCase('schedule report')}
                        </Drip7Button>
                    </div>
                </div>

                <table className='management__table'>
                    <tbody>
                        <tr className='management__table-row management__table-row--head'>
                            <th className='management__table-head management__table-head--input'>
                                <div className='d7__checkbox-container'>
                                    <input
                                        className='d7__checkbox'
                                        aria-label="checkbox"
                                        type='checkbox'
                                        checked={reports.length > 0 && reports.length == Object.keys(selected).length}
                                        onChange={e => handleSelectAll( e.target.checked )}/>
                                    <span className='dash'></span>
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--name'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('report name')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('type')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('delivery')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('data range')}
                                </h5>
                            </th>
                            <th className='management__table-head management__table-head--questions'>
                                <h5 className='management__table-heading'>
                                    {Language.getTitleCase('generate report')}
                                </h5>
                            </th>
                        </tr>
                        {Object.entries(filtered_reports).map(
                            ([key, report]) => (
                                
                                <tr
                                    className='management__table-row management__table-row--body'
                                    key={"report_row_" + key}>
                                    <td className='management__table-data management__table-data--input'>
                                        <div className='d7__checkbox-container'>
                                            <input
                                                className='d7__checkbox'
                                                aria-label="checkbox"
                                                type='checkbox'
                                                checked={ report.uid in selected }
                                                onChange={e => handleSelect( e.target.checked, report.uid ) } />
                                            <span className='checkmark'></span>
                                        </div>
                                    </td>
                                    <td
                                        className='management__table-data management__table-data--name'
                                        onClick={() => setState(prev => ({...prev, show_generate: report })) }>
                                        <h5 className='management__table-text'>
                                            {report.name}
                                        </h5>
                                    </td>
                                    <td
                                      className='management__table-data'
                                      onClick={() => setState(prev => ({...prev, show_generate: report })) }>
                                        <h5 className='management__table-text'>
                                            {report.report}
                                        </h5>
                                    </td>
                                    <td
                                        className='management__table-data'
                                        onClick={() => setState(prev => ({...prev, show_generate: report })) }>
                                        <h5 className='management__table-text'>
                                            {report.delivery}
                                        </h5>
                                    </td>
                                    <td
                                        className='management__table-data'
                                        onClick={() => setState(prev => ({...prev, show_generate: report })) }>
                                        <h5 className='management__table-text'>
                                            {report.data_range}
                                        </h5>
                                    </td>
                                    <td
                                      className='management__table-data'
                                      onClick={() => setState(prev => ({...prev, show_generate: report })) }>
                                        <Drip7Button
                                            idBtn={'generateContent' + key}
                                            onClick={() => setState(prev => ({...prev, show_generate: report, generate_only: true })) }>
                                            {Language.getTitleCase('generate')}
                                        </Drip7Button>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState(prev => ({...prev, confirm: null }))}
                    />
                )}
            </div>

            <Drip7Modal>
                {show_generate != null && (
                  <ReportGenerateModal
                    report={show_generate}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                    generate_only={generate_only}
                    onClose={handleModalClose}
                  />
                )}
            </Drip7Modal>
        </div>
    );
};